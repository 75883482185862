import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Link = {
  text: string;
  url: string;
};

interface NewsLinksProps {
  id: string | undefined;
  link: Link[];
  textLink?: string;
}

export const NewsLinks = ({ id, link, textLink }: NewsLinksProps) => {
  const [t] = useTranslation('global');
  if (id === '5') {
    return (
      <Typography
        sx={{ fontSize: '15px', lineHeight: '24px', pb: { xs: '6px' }, pt: { xs: '6px' } }}
      >
        {textLink && t(textLink)}
        {link &&
          link.map(({ text, url }) => (
            <Link to={url} style={{ color: 'inherit' }} target='_blank'>
              {t(text)}
            </Link>
          ))}
      </Typography>
    );
  }
  if (id === '8') {
    return (
      <Typography
        sx={{ fontSize: '15px', lineHeight: '24px', pb: { xs: '6px' }, pt: { xs: '6px' } }}
      >
        {textLink && t(textLink)}
        {link &&
          link.map(({ text, url }) => (
            <Link to={url} style={{ color: 'inherit' }} target='_blank'>
              {t(text)}
            </Link>
          ))}
      </Typography>
    );
  }
  if (id === '2' || id === '3' || id === '1') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          sx={{
            fontSize: '15px',
            lineHeight: '24px',
            pb: { xs: '6px' },
            pt: { xs: '6px' },
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {link &&
            link.map(({ text, url }) => (
              <Box>
                {t(text)}
                <Link to={url} style={{ color: 'inherit' }} target='_blank'>
                  {url}
                </Link>
              </Box>
            ))}
        </Typography>
      </Box>
    );
  }
  return null;
};
