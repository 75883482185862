import { BlockForDownloadPdfCSS, ButtonCSS, ListCSS, Wrapper } from './styles';
import { Box, Link as MuiLink, List, ListItem, Stack, Typography } from '@mui/material';
import { palette } from '../../theme/foundations';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadPDF } from '../../utils';
import { useTranslation } from 'react-i18next';

export const DatenschutzPage = () => {
  const [t] = useTranslation('global');
  const handleDownload = () => downloadPDF('DatenschutzPage');

  return (
    <Wrapper>
      <Box mb={'30px'}>
        <ButtonCSS onClick={handleDownload} variant={'popup'} endIcon={<DownloadIcon />}>
          {t('privacyPolicy.saveAsPdf')}
        </ButtonCSS>
      </Box>
      <BlockForDownloadPdfCSS>
        <Typography variant={'h4'}>{t('privacyPolicy.title')}</Typography>
        <Typography variant={'h5'}>{t('privacyPolicy.generalNotice')}</Typography>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>{t('privacyPolicy.responsibleEntityTitle')}</Typography>
          <List subheader={t('privacyPolicy.responsibleEntity')}>
            <ListItem>{t('privacyPolicy.company')}</ListItem>
            <ListItem>{t('privacyPolicy.contactPerson')}</ListItem>
            <ListItem>{t('privacyPolicy.address')}</ListItem>
            <ListItem>{t('privacyPolicy.city')}</ListItem>
            <ListItem>{t('privacyPolicy.dataProcessingNotice')}</ListItem>
          </List>
        </Stack>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>{t('privacyPolicy.consentWithdrawalTitle')}</Typography>
          <Typography>{t('privacyPolicy.consentWithdrawal')}</Typography>
        </Stack>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>{t('privacyPolicy.complaintTitle')}</Typography>
          <Typography>
            {t('privacyPolicy.complaint')}{' '}
            <MuiLink
              sx={{ color: palette.error.main, overflowWrap: 'anywhere' }}
              target='_blank'
              href={t('privacyPolicy.link')}
            >
              {t('privacyPolicy.link')}
            </MuiLink>
          </Typography>
        </Stack>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>{t('privacyPolicy.dataPortabilityTitle')}</Typography>
          <Typography>{t('privacyPolicy.dataPortability')}</Typography>
        </Stack>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>{t('privacyPolicy.accessRightsTitle')}</Typography>
          <Typography>{t('privacyPolicy.accessRights')}</Typography>
        </Stack>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>{t('privacyPolicy.sslTitle')}</Typography>
          <Typography>{t('privacyPolicy.ssl')}</Typography>
        </Stack>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>{t('privacyPolicy.logFilesTitle')}</Typography>
          <Typography>{t('privacyPolicy.logFiles')}</Typography>
          <ListCSS>
            <ListItem>{t('privacyPolicy.visitedPage')}</ListItem>
            <ListItem>{t('privacyPolicy.date')}</ListItem>
            <ListItem>{t('privacyPolicy.browserType')}</ListItem>
            <ListItem>{t('privacyPolicy.os')}</ListItem>
            <ListItem>{t('privacyPolicy.referrerUrl')}</ListItem>
            <ListItem>{t('privacyPolicy.hostname')}</ListItem>
            <ListItem>{t('privacyPolicy.ipAddress')}</ListItem>
          </ListCSS>
          <Typography>{t('privacyPolicy.logFilesNote')}</Typography>
        </Stack>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>{t('privacyPolicy.registrationTitle')}</Typography>
          <Typography>{t('privacyPolicy.registration')}</Typography>
          <Typography>{t('privacyPolicy.registrationEmail')}</Typography>
          <Typography>{t('privacyPolicy.registrationNote')}</Typography>
          <Typography>{t('privacyPolicy.registrationStorage')}</Typography>
        </Stack>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>{t('privacyPolicy.contactFormTitle')}</Typography>
          <Typography>{t('privacyPolicy.contactForm')}</Typography>
          <Typography>{t('privacyPolicy.contactFormNote')}</Typography>
          <Typography>{t('privacyPolicy.contactFormStorage')}</Typography>
        </Stack>
      </BlockForDownloadPdfCSS>
    </Wrapper>
  );
};
