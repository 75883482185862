import React from 'react';
import { styled } from '@mui/material';

import { IconComponentPropsType } from '../../types';

export const CheckIcon = ({
  width = '20px',
  height = '20px',
  style,
}: // color = palette.white100,
IconComponentPropsType) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='mask0_3354_23390'
        style={style}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width={width}
        height={height}
      >
        <rect width='20' height='20' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_3354_23390)'>
        <path
          d='M8.33341 11.6055L13.4167 6.52214C13.5695 6.36936 13.7466 6.29297 13.948 6.29297C14.1494 6.29297 14.3265 6.36936 14.4792 6.52214C14.632 6.67491 14.7084 6.852 14.7084 7.05339C14.7084 7.25477 14.632 7.43186 14.4792 7.58464L8.85425 13.2096C8.70147 13.3624 8.52439 13.4388 8.323 13.4388C8.12161 13.4388 7.94453 13.3624 7.79175 13.2096L5.52091 10.9388C5.36814 10.786 5.29175 10.6089 5.29175 10.4076C5.29175 10.2062 5.36814 10.0291 5.52091 9.8763C5.67369 9.72352 5.85078 9.64714 6.05216 9.64714C6.25355 9.64714 6.43064 9.72352 6.58341 9.8763L8.33341 11.6055Z'
          fill='#012960'
        />
      </g>
    </svg>
  );
};
