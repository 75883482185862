import * as yup from 'yup';

export const createRegisterFormSchema = (t: (key: string) => string) => {
  return yup.object().shape({
    email: yup.string().email(t('messages.incorrectEmail')).required(t('messages.requireMessage')),
    password: yup.string().required(t('messages.requireMessage')),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('messages.passNotMatch'))
      .required(t('messages.requireMessage')),
  });
};
