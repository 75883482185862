import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import i18next from 'i18next';
import en from '../src/translations/en/en.json';
import de from '../src/translations/de/de.json';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'de',
  resources: {
    en: {
      global: en,
    },
    de: {
      global: de,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
);
