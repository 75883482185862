import { Button, styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { theme } from '../../theme';
import { LanguageIcon } from '../icons/LanguageIcon';

export const StyledAppBar = styled(AppBar)(() => ({
  display: 'grid',
  width: '100%',
  gap: '24px',
  gridTemplateColumns: 'max-content 1fr max-content',
  gridTemplateAreas: `"logo buttonGroup languageButton"`,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '24px 90px',
  background: 'linear-gradient(162deg, rgba(1, 41, 96, 0.90) 0%, rgba(36, 82, 147, 0.9) 100%)',
  boxShadow: 'none',
  [theme.breakpoints.down('lg')]: {
    gridTemplateAreas: `"logo languageButton"
    "buttonGroup buttonGroup"`,
    gridTemplateColumns: 'max-content max-content',
  },

  [theme.breakpoints.down('md')]: {
    padding: '20px 10px',
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '4px',
  },
}));

export const ButtonGroup = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '18px',
  maxWidth: '547px',
  marginLeft: 'auto',

  [theme.breakpoints.up('gxl')]: {
    maxWidth: '30vw',
  },

  [theme.breakpoints.down('lg')]: {
    margin: '0 auto',
    maxWidth: '100%',
    width: '100%',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const Logo = styled(Box)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  img: {
    marginBottom: '-4px',
  },
  [theme.breakpoints.down('xl')]: {
    svg: {
      width: '370px',
    },
  },
  [theme.breakpoints.up('gxl')]: {
    svg: {
      width: '20vw',
      height: '2vw',
    },
  },

  [theme.breakpoints.down('sm')]: {
    svg: {
      width: 'calc(100dvw - 95px)',
      maxWidth: '300px',
    },
  },
}));

export const ButtonCSS = styled(Button)(({ theme }) => ({
  minWidth: 'fit-content',
  width: '100%',
  textWrap: 'nowrap',

  [theme.breakpoints.down(500)]: {
    width: '100%',
    fontSize: '14px',
    padding: '10px 14px',
    margin: '0 auto',
  },
  [theme.breakpoints.up('gxl')]: {
    height: '2.5vw',
    fontSize: '0.9vw',
    borderRadius: '24px',
  },
}));

export const LanguageButtonStyling = styled(Button, { label: 'Header-LanguageButtonStyling' })({
  gridArea: 'languageButton',
  width: '68px',
  minWidth: '0',
  height: 'auto',
  padding: '10px',
  display: 'flex',
  gap: '4px',
  marginLeft: 'auto',
  textTransform: 'none',
  fontWeight: 400,

  [theme.breakpoints.up('gxl')]: {
    width: '3vw',
    gap: '12px',
  },
});

export const LanguageIconStyles = styled(LanguageIcon, { label: 'Header-LanguageIconStyles' })({
  height: '16px',
  [theme.breakpoints.up('gxl')]: {
    height: '100%',
  },
});
