import closeIcon from '../../assets/icon/closeIcon.svg';
import { Box } from '@mui/material';
import { useEffect } from 'react';

type Image = {
  url: string;
  description?: string;
};

type ImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  images?: Image[];
  image: number;
  newsId: string | undefined;
};

export const ImageModal = ({ isOpen, onClose, images, image, newsId }: ImageModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);
  return isOpen && images ? (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 41, 96, 0.8)',
          zIndex: 1200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ position: 'relative', width: '100%', height: '100%', maxWidth: '1300px' }}>
          <Box
            sx={{
              position: 'fixed',
              top: '24px',
              right: '24px',
              cursor: 'pointer',
              height: '90%',
              zIndex: 1300,
            }}
            onClick={onClose}
          >
            <img src={closeIcon} alt='close_icon' />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90vw',
              maxWidth: 'calc(100vw - 130px)',
              height: '90vh',
              maxHeight: 'calc(100vh - 130px)',
              zIndex: 1600,
            }}
          >
            <img
              src={newsId === '6' ? images[0].url : images[image].url}
              alt='fullImage'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  ) : null;
};
