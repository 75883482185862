import { IconComponentPropsType } from '../../types';
import immobilienzeitung from '../../assets/images/immobilienzeitung.png';

export const ImmobilienZeitung = ({
  width = '123px',
  height = '32px',
  style,
}: IconComponentPropsType) => {
  return (
    <img
      width={width}
      height={height}
      style={style}
      src={immobilienzeitung}
      alt='innovationsradar'
    />
  );
};
