export const getPopperMenuText = (index: number) => {
  switch (index) {
    case 0: // CENTER
      return 'main';
    case 1: // Entwickler
      return 'borrower';
    case 2: // EIGENTÜMER
      return 'propertyOwner';
    case 3: // BANKEN
      return 'bank';
    default:
      return '';
  }
};
