import { IconComponentPropsType } from '../../types';

export const DeLanguageLetterIcon = ({
  width = '100%',
  height = '100%',
  style,
}: // color = palette.white100,
IconComponentPropsType) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={style}
  >
    <path
      d='M4.22985 12H0V0H4.2648C5.46501 0 6.49819 0.240235 7.36436 0.720703C8.23053 1.19727 8.89666 1.88281 9.36276 2.77734C9.83274 3.67188 10.0677 4.74219 10.0677 5.98828C10.0677 7.23828 9.83274 8.3125 9.36276 9.21094C8.89666 10.1094 8.22664 10.7988 7.35271 11.2793C6.48265 11.7598 5.4417 12 4.22985 12ZM2.52276 9.82617H4.12497C4.87073 9.82617 5.49802 9.69336 6.00685 9.42774C6.51955 9.1582 6.90409 8.74219 7.16044 8.17969C7.42068 7.61328 7.5508 6.88281 7.5508 5.98828C7.5508 5.10156 7.42068 4.37695 7.16044 3.81445C6.90409 3.25195 6.5215 2.83789 6.01267 2.57227C5.50385 2.30664 4.87656 2.17383 4.1308 2.17383H2.52276V9.82617Z'
      fill='white'
    />
    <path
      d='M11.9365 12V0H19.9767V2.0918H14.4593V4.95117H19.563V7.04297H14.4593V9.9082H20V12H11.9365Z'
      fill='white'
    />
  </svg>
);
