import { useState, MouseEvent, useRef, useEffect } from 'react';
import { TriangleArrowIcon } from '../icons';
import Box from '@mui/material/Box';
import { ClickAwayListener, SxProps } from '@mui/material';
import { palette } from '../../theme/foundations';
import { PopperPlacementType } from '@mui/base/Popper/Popper.types';
import { ButtonCSS } from './styles';
import { PopperCustom } from 'components';
import { useTranslation } from 'react-i18next';

type PopoverButtonProps = {
  containerStyles?: SxProps;
  variant?: 'outlined' | 'text' | 'contained' | 'submit' | 'popup';
  text: string;
  popoverMenuText: string;
  popoverPlacement: PopperPlacementType;
  popoverStyles: SxProps;
  isClosePopover: boolean;
};

export const PopoverButton = ({
  containerStyles,
  text,
  variant,
  popoverMenuText,
  popoverPlacement,
  popoverStyles,
  isClosePopover,
}: PopoverButtonProps) => {
  const popperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [t] = useTranslation('global');

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const canBeOpen = isOpen && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  useEffect(() => {
    setIsOpen(false);
  }, [isClosePopover]);

  const openConditionIcon = isOpen ? (
    <TriangleArrowIcon
      style={{
        transform: 'rotate(180deg)',
      }}
      color={isOpen ? palette.blue600 : palette.primary.main}
    />
  ) : (
    <TriangleArrowIcon />
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ ...containerStyles, zIndex: 6 }}>
        <ButtonCSS
          variant={variant}
          onClick={handleOpen}
          endIcon={openConditionIcon}
          isopen={isOpen ? 'true' : 'false'}
        >
          {t(`introTitle.${text}`)}
        </ButtonCSS>
        <PopperCustom
          popperRef={popperRef}
          id={id}
          anchorEl={anchorEl}
          open={isOpen}
          popperMenuText={text}
          popperSx={popoverStyles}
          placement={popoverPlacement}
        />
      </Box>
    </ClickAwayListener>
  );
};
