import { LinkedinIcon, InnovationsradarIcon, ImmobilienZeitung } from '../components/icons';
import { PopperPlacementType } from '@mui/base/Popper/Popper.types';
import { SxProps } from '@mui/material';
import { Link } from 'react-router-dom';

export const AVAILABLE_LANGS = ['en', 'de'];

export const ROUTES = {
  home: '/',
  impressum: '/impressum',
  datenschutz: '/datenschutz',
  nachrichten: '/news',
  immobilienzeitung: '/immobilienzeitung',
  linkedin: 'https://www.linkedin.com/company/dexprime/',
  innovationsradar: 'https://www.zia-innovationsradar.de/',
};

export const imprintPageVariables = {
  companyName: 'DEX prime GmbH',
  addressLine1: 'Gärtnerweg 9',
  index: '60322',
  city: 'Frankfurt am Main',
  managingDirector: 'Yary Eumenius-Schulz',
  email: 'E-Mail: ',
  website: 'Internet: ',
  websiteLink: (
    <Link
      to='https://dexprime.com'
      style={{ color: 'inherit', textDecoration: 'none' }}
      target='_blank'
    >
      https://dexprime.com
    </Link>
  ),
  emailLink: (
    <Link
      to='mailto:info@dexprime.com'
      style={{ color: 'inherit', textDecoration: 'none' }}
      target='_blank'
    >
      info@dexprime.com
    </Link>
  ),
  vatIdentificationIndex: 'DE348466324 ',
  commercialRegister: 'HRB 123469',
};

export const MENU_LINKS = [
  {
    path: ROUTES.home,
    content: 'dexPrime',
    subContent: null,
    image: null,
    localize: true,
  },
  {
    path: ROUTES.home,
    content: 'email',
    subContent: 'info@dexprime.com',
    image: null,
    localize: false,
  },
  {
    path: ROUTES.impressum,
    content: 'imprint',
    subContent: null,
    image: null,
    localize: true,
  },
  {
    path: ROUTES.datenschutz,
    content: 'privacyPolicy',
    subContent: null,
    image: null,
    localize: true,
  },
  // {HRB 123469
  //   path: ROUTES.nachricht,
  //   content: 'Nachricht',
  //   subContent: null,
  //   image: null,
  // },
  {
    path: ROUTES.linkedin,
    content: null,
    subContent: null,
    image: LinkedinIcon,
    localize: false,
  },
  {
    path: ROUTES.innovationsradar,
    content: null,
    subContent: null,
    image: InnovationsradarIcon,
    localize: false,
  },
  {
    path: ROUTES.immobilienzeitung,
    content: null,
    subContent: null,
    image: ImmobilienZeitung,
    localize: false,
  },
];

export const MOBILE_MENU_LINKS = [
  [
    {
      path: ROUTES.home,
      content: 'dexPrime',
      subContent: null,
      image: null,
      localize: true,
    },
    {
      path: ROUTES.home,
      content: null,
      subContent: 'info@dexprime.com',
      image: null,
      localize: false,
    },
  ],
  [
    {
      path: ROUTES.impressum,
      content: 'imprint',
      subContent: null,
      image: null,
      localize: true,
    },
    {
      path: ROUTES.datenschutz,
      content: 'privacyPolicy',
      subContent: null,
      image: null,
      localize: true,
    },
    // {
    //   path: ROUTES.nachricht,
    //   content: 'Nachricht',
    //   subContent: null,
    //   image: null,
    // },
  ],
  [
    {
      path: ROUTES.linkedin,
      content: null,
      subContent: null,
      image: LinkedinIcon,
      localize: false,
    },
    {
      path: ROUTES.innovationsradar,
      content: null,
      subContent: null,
      image: InnovationsradarIcon,
      localize: false,
    },
    {
      path: ROUTES.immobilienzeitung,
      content: null,
      subContent: null,
      image: ImmobilienZeitung,
      localize: false,
    },
  ],
];

// ! 26.51vw or 509px
export const DRAWER_WIDTH = 26.51;

export enum TABS_OPTIONS {
  projektentwickler = 'Projektentwickler',
  bank = 'Bank',
  drittsicherheitengeber = 'Drittsicherheitengeber',
  login = 'Login',
  registrieren = 'Registrieren',
}

export enum POPOVERCONTENT {
  GRANDSCHULDPOPOVERTEXT = 'Abtretung von Grundschulden (Drittsicherheiten) zur Besicherung der Projektfinanzierung basierend auf individuellen Allokationskriterien des Eigentümers.',
  DARLEHENPOPOVERTEXT = 'Erhöhung des Kreditrahmens für die Projektfinanzierung dank Drittsicherheiten.',
  BANKENPOPOVERTEXT = '(Banken aktiv in Projektfinanzierungen) \n Mehr Neugeschäft ohne signifikanten Zusatzaufwand. Erhöhung der Rendite auf das hinterlegte regulatorische und ökonomische Risikokapital dank Drittbesicherung.',
  PRAMIE = 'Risikoadäquate Vergütung des Eigentümers für die Übernahme des Kreditrisikos des Entwicklers.',
  ENTWICKLER = '(Entwickler für Immobilien & Erneuerbare Energien) \n Schließung der Mezzanine-Finanzierungslücke durch Erhöhung des Kreditrahmens des Bankdarlehens. Vereinfachte Finanzierungsstruktur durch One-Stop-Shop-Finanzierung der Partnerbank.',
  EIGENTÜMER = '(Family-Offices, Stiftungen, Immobiliengesellschaften) \n Aktivierung des schlummernden Renditepotenzials (Beleihungspotenzial) einer (zum Teil) abbezahlten Immobilie oder Wald- & Agrarfläche über den Miet- bzw. Pachtertrag hinaus. Umfangreicher Schutz der Grundschulden durch mehrstufige Risikominderungsmechanismen durch DEX',
}

type PopoverButtonsType = {
  text: 'propertyOwner' | 'collateral' | 'bank' | 'loan' | 'premium' | 'borrower';
  containerStyles: Record<string, string | number | object>;
  isMobileContainerStyles: Record<string, string | number | object>;
  popoverMenuText: string;
  popoverPlacement: PopperPlacementType;
  isMobilePopoverPlacement: PopperPlacementType;
  popoverStyles: SxProps;
  isMobilePopoverStyles: SxProps;
};

export const buttons: PopoverButtonsType[] = [
  {
    text: 'propertyOwner',
    containerStyles: {
      position: 'absolute',
      left: '6%',
      bottom: '32px',
    },
    isMobileContainerStyles: {
      position: 'absolute',
      left: '0',
      bottom: '32px',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.EIGENTÜMER,
    popoverPlacement: 'left-end',
    isMobilePopoverPlacement: 'top',
    popoverStyles: { marginRight: '10px' },
    isMobilePopoverStyles: { marginBottom: '10px', marginTop: '10px' },
  },
  {
    text: 'collateral',
    containerStyles: { position: 'absolute', top: '42%', right: '68%' },
    isMobileContainerStyles: {
      position: 'absolute',
      left: '0',
      top: '42%',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.GRANDSCHULDPOPOVERTEXT,
    popoverPlacement: 'top-end',
    isMobilePopoverPlacement: 'top-end',
    popoverStyles: { marginBottom: '10px', marginTop: '10px' },
    isMobilePopoverStyles: { marginBottom: '10px' },
  },
  {
    text: 'bank',
    containerStyles: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
    isMobileContainerStyles: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.BANKENPOPOVERTEXT,
    popoverPlacement: 'bottom',
    isMobilePopoverPlacement: 'bottom',
    popoverStyles: { marginTop: '10px' },
    isMobilePopoverStyles: { marginTop: '10px' },
  },
  {
    text: 'loan',
    containerStyles: { position: 'absolute', left: '68%', top: '42%' },
    isMobileContainerStyles: {
      position: 'absolute',
      right: '0',
      top: '42%',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.DARLEHENPOPOVERTEXT,
    popoverPlacement: 'top-start',
    isMobilePopoverPlacement: 'top-start',
    popoverStyles: { marginBottom: '10px' },
    isMobilePopoverStyles: { marginBottom: '10px' },
  },
  {
    text: 'premium',
    containerStyles: {
      position: 'absolute',
      bottom: '20%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    isMobileContainerStyles: {
      position: 'absolute',
      bottom: '78px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.PRAMIE,
    popoverPlacement: 'bottom',
    isMobilePopoverPlacement: 'bottom',
    popoverStyles: { marginBottom: '10px', marginTop: '10px' },
    isMobilePopoverStyles: { marginBottom: '10px', marginTop: '10px' },
  },
  {
    text: 'borrower',
    containerStyles: { position: 'absolute', right: '6%', bottom: '34px' },
    isMobileContainerStyles: {
      position: 'absolute',
      right: '0',
      bottom: '34px',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.ENTWICKLER,
    popoverPlacement: 'right-end',
    isMobilePopoverPlacement: 'top',
    popoverStyles: { marginLeft: '10px' },
    isMobilePopoverStyles: { marginBottom: '10px', marginTop: '10px' },
  },
];
