import { createTheme } from '@mui/material';

import * as foundations from './foundations';
import { palette } from './foundations';

export const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: {
            variant: 'outlined',
          },
          style: {
            border: `1px solid ${palette.primary.main}`,
            borderRadius: '6px',
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 24px',
            height: '48px',
            fontSize: '16px',
            lineHeight: '130%',
            fontWeight: 700,
            textTransform: 'uppercase',
            color: palette.white,
            cursor: 'pointer',
          },
        },
        {
          props: {
            variant: 'submit',
          },
          style: {
            borderRadius: '1000px',
            background: palette.white100,
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.06)',
            display: 'flex',
            alignItems: 'center',
            padding: '11px 32px',
            height: '49px',
            gap: '7px',
            fontSize: '18px',
            fontWeight: 600,
            textTransform: 'uppercase',
            color: palette.secondary.main,
            fontFamily: 'Poppins, sans-serif',
            '&:hover': {
              background: palette.white100,
            },
            '.MuiLoadingButton-loadingIndicator': {
              right: '35px',
            },
            '.MuiCircularProgress-root': {
              width: '1.25vw !important',
              height: '1.25vw !important',
            },
          },
        },
        {
          props: {
            variant: 'popup',
          },
          style: {
            border: `1px solid ${palette.white100}`,
            borderRadius: '6px',
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
            padding: '6px 10px',
            height: '34px',
            fontSize: '16px',
            lineHeight: '130%',
            fontWeight: 700,
            textTransform: 'uppercase',
            color: palette.white,
            cursor: 'pointer',
            '&:hover': {
              background: 'transparent',
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: palette.gray500,
          borderRadius: '0px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'medium',
      },
      variants: [
        {
          props: {
            size: 'medium',
          },
          style: {
            input: {
              padding: 0,
            },
            '.MuiInputBase-root': {
              paddingInlineStart: '24px',
              paddingInlineEnd: '4px',
              minHeight: '44px',
              borderRadius: '8px',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '18px',
              color: palette.blue500,
              '& fieldset': {
                border: 'none',
              },
              background: palette.white,
            },
            '.MuiFormHelperText-root': {
              position: 'absolute',
              top: '-24px',
              right: 0,
              marginTop: '0.16vw',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '& [placeholder]::placeholder': {
            color: palette.blue500,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px',
            opacity: 1,
          },
          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: '0',
          },
          input: {
            MozAppearance: 'textfield', // FOR MOZILA
          },
        },
      },
    },
  },
  ...foundations,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1200,
      xl: 1536,
      gxl: 1921,
    },
  },
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    submit: true;
    popup: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    gxl: true;
  }
}
