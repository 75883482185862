import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '19px',
    marginBottom: '85px',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    width: '658px',
    minHeight: '632px',
    [theme.breakpoints.up('gxl')]: {
      width: '50vw',
      marginTop: '0',
      marginBottom: '2vw',
      paddingTop: '0.5vw',
    },
    [theme.breakpoints.down(1200)]: {
      marginTop: '39px',
    },
    [theme.breakpoints.down('md')]: {
      width: '500px',
      minHeight: 'auto',
      marginTop: '39px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  };
});

export const SpinnerBlockCSS = styled(Box)(({ theme }) => {
  return {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '50px 0',
    },
    [theme.breakpoints.up('gxl')]: {},
  };
});
