import { Link } from 'react-router-dom';

import { MOBILE_MENU_LINKS, MENU_LINKS } from '../../constants';
import { useMediaQueryCustom } from '../../hooks/useMediaQueryCustom';
import { BgImage, LinksWrapper, StyledFooter, MobileMenuRow } from './styles';
import { useTranslation } from 'react-i18next';
import { useCustomHref } from '../../hooks/useCustomHref';

export const Footer = () => {
  const [t] = useTranslation('global');
  const { isMobile } = useMediaQueryCustom();
  const { createHref } = useCustomHref();

  return (
    <StyledFooter>
      <BgImage />
      <LinksWrapper>
        {isMobile
          ? MOBILE_MENU_LINKS.map((menuRow) => {
              return (
                <MobileMenuRow>
                  {menuRow.map(({ path, content, subContent, image: Image, localize }) => {
                    return (
                      <Link key={path} to={createHref(path, localize)}>
                        {content ? t(`footer.${content}`) : ''} <span>{subContent}</span>{' '}
                        {Image && <Image />}
                      </Link>
                    );
                  })}
                </MobileMenuRow>
              );
            })
          : MENU_LINKS.map(({ path, content, subContent, image: Image, localize }, i) => {
              return (
                <Link key={i} to={createHref(path, localize)}>
                  {content ? t(`footer.${content}`) : ''} <span>{subContent}</span>{' '}
                  {Image && <Image />}
                </Link>
              );
            })}
      </LinksWrapper>
    </StyledFooter>
  );
};
