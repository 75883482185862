import { Language } from '../types';

const LANGUAGE_LOCAL_STORAGE_KEY = 'lang';

export const langLocalStorageHandler = {
  setLanguage: (lang: Language | null): void => {
    localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, JSON.stringify(lang));
  },

  getLanguage: (): Language | undefined => {
    if (typeof localStorage !== 'undefined') {
      const lang = localStorage?.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
      return lang ? JSON.parse(lang) : undefined;
    }
    return;
  },

  clearLang: () => {
    localStorage.removeItem(LANGUAGE_LOCAL_STORAGE_KEY);
  },
};
