import { useNavigate, useParams } from 'react-router-dom';
import arrowBack from '../../../../src/assets/icon/arrowBack.svg';
import { useTranslatedNewsData } from '../../../assets/data/news';
import { Box, Typography } from '@mui/material';
import { NewsImages } from '../../../components/NewsImages/NewsImages';
import React, { useEffect, useState } from 'react';
import { NewsParagraphs } from '../../../components/NewsParagraphs/NewsParagraphs';
import { NewsLinks } from '../../../components/NewsLinks/NewsLinks';
import { ImageModal } from '../../../components/ImageModal';
import { useTranslation } from 'react-i18next';
import NotFoundPage from '../../NotFoundPage';

export const NachrichtDetailedPage = () => {
  const [t] = useTranslation('global');
  const [isOpen, setIsOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const newsData = useTranslatedNewsData();
  const news = newsData.find((item) => item.id?.toString() === id);

  if (!news) return <NotFoundPage errorText={'notNews'} />;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleIsOpen = (index: number) => {
    setIsOpen((prev) => !prev);
    setActiveImageIndex(index);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
          pb: '6px',
          cursor: 'pointer',
          marginTop: '10px',
        }}
        onClick={() => navigate(-1)}
      >
        <img src={arrowBack} alt='arrowBack' />
        <Typography sx={{ fontSize: '14px' }}> {t('header.back_button')}</Typography>
      </Box>
      <Typography
        sx={{
          fontSize: { xs: '24px', md: '34px' },
          lineHeight: { xs: '32px', md: '40px' },
          pb: { xs: '20px', md: '36px' },
        }}
      >
        {t(`news.id_${news?.id}.title`)}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: id === '3' ? { xs: 'column', md: 'row' } : 'column',
          gap: id === '3' ? { md: '15px' } : 0,
        }}
      >
        <NewsImages images={news?.images} newsId={id} onOpen={handleIsOpen} />
        <ImageModal
          isOpen={isOpen}
          onClose={handleClose}
          images={news?.images}
          image={activeImageIndex}
          newsId={id}
        />
        <Box
          sx={{
            pt: id === '3' ? { xs: '20px', md: '0px' } : { xs: '20px', md: '36px' },
            width: '100%',
          }}
        >
          <NewsParagraphs
            paragraphs={Array.from(t(`news.id_${id}.paragraphs`, { returnObjects: true }))}
            id={id}
          />
          {news?.link && <NewsLinks id={id} link={news.link} textLink={news.textLink} />}
          <Typography sx={{ pt: { xs: '10px', md: '36px' }, fontSize: '15px' }}>
            {t(`news.id_${id}.date`)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
