import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(() => ({
  position: 'relative',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const ImageBackground = styled(Box)(({ firstImage }: { firstImage: string }) => ({
  backgroundImage: `url(${firstImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  borderRadius: '12px',
  width: '100%',
  height: '100%',
}));

export const textStyles = {
  title: {
    fontSize: '34px',
    lineHeight: '40px',
  },
  content: {
    fontSize: '15px',
  },
  cropped: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  },
};
