import { Button, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { theme } from '../../theme';

export const ImageBackground = styled(Box)(({ image }: { image: string }) => ({
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  borderRadius: '12px',
  width: '100%',
  height: '100%',
}));

export const ImageWrapper = styled(Box, { label: 'NewsImages-ImageWrapper' })({
  display: 'flex',
  gap: '28px',
  padding: '0',
  alignItems: 'start',
  [theme.breakpoints.down('md')]: {
    gap: '20px',
  },

  [theme.breakpoints.down(500)]: {
    flexDirection: 'column',
  },
});

export const DescriptionStyles = styled(Typography, { label: 'NewsImages-DescriptionStyles' })({
  fontSize: '12px',
  lineHeight: '20px',
  maxWidth: 'max-content',
  textTransform: 'none',
  [theme.breakpoints.up('md')]: {
    paddingTop: '8px',
  },

  [theme.breakpoints.down('xs')]: {
    paddingTop: '4px',
  },
});
