import { Stack, Typography } from '@mui/material';
import { BlockForDownloadPdfCSS, Wrapper } from './styles';
import { useTranslation } from 'react-i18next';
import { imprintPageVariables } from '../../constants';

export const ImpressumPage = () => {
  const { t } = useTranslation('global');
  return (
    <Wrapper>
      <BlockForDownloadPdfCSS>
        <Stack mb={4} gap={'10px'}>
          <Typography>{imprintPageVariables.companyName}</Typography>
          <Typography>{imprintPageVariables.addressLine1}</Typography>
          <Typography>
            {imprintPageVariables.index} {imprintPageVariables.city}
          </Typography>

          <Typography>
            {t('imprint.managingDirector')} {imprintPageVariables.managingDirector}
          </Typography>

          <Typography>
            {imprintPageVariables.email}
            {imprintPageVariables.emailLink}
          </Typography>
          <Typography>
            {imprintPageVariables.website}
            {imprintPageVariables.websiteLink}
          </Typography>

          <Typography>{t('imprint.vatIdentification')}</Typography>
          <Typography>
            {imprintPageVariables.vatIdentificationIndex}
            {t('imprint.vatIdentificationLine1')}
          </Typography>
          <Typography>
            {t('imprint.localCourt')} {imprintPageVariables.city}
          </Typography>
          <Typography>
            {t('imprint.commercialRegister')}
            {imprintPageVariables.commercialRegister}
          </Typography>
        </Stack>
        <Typography>{t('imprint.disclaimer')}</Typography>
      </BlockForDownloadPdfCSS>
    </Wrapper>
  );
};
