import {
  ButtonCSS,
  ButtonGroup,
  LanguageButtonStyling,
  LanguageIconStyles,
  Logo,
  StyledAppBar,
} from './styles';
import { LogoIcon } from '../icons';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { CheckIcon } from '../icons/CheckIcon';
import { useEffect, useState } from 'react';
import { langLocalStorageHandler } from '../../utils/langLocalStorageHandler';
import { useCustomHref } from '../../hooks/useCustomHref';
import { useUrlLang } from '../../hooks/useUrlLang';
import { Language } from '../../types';
import { DeLanguageLetterIcon } from '../icons/DeLanguageLetterIcon';
import { EnLanguageLetterIcon } from '../icons/ EnLanguageLetterIcon';
import { LanguageIcon } from '../icons/LanguageIcon';
import { palette } from '../../theme/foundations';

interface HeaderProps {
  onOpenDrawer: () => void;
}

export const Header = ({ onOpenDrawer }: HeaderProps) => {
  const [t, i18n] = useTranslation('global');
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const { urlLanguage, urlLanguageRaw, setUrlLanguage } = useUrlLang();
  const [activeLanguage, setActiveLanguage] = useState<Language>();
  const { createHref } = useCustomHref();
  const navigate = useNavigate();

  const homeHref = createHref(ROUTES.home);

  const handleChangeLanguage = (lang: Language) => {
    langLocalStorageHandler.setLanguage(lang);
    setActiveLanguage(lang);
    setUrlLanguage(lang);
    setIsLanguageModalOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const currentLang = langLocalStorageHandler.getLanguage();

    if (!urlLanguageRaw && currentLang) {
      setActiveLanguage(currentLang);
      currentLang !== 'de' && setUrlLanguage(currentLang);
    } else {
      langLocalStorageHandler.setLanguage(urlLanguage);
      setActiveLanguage(urlLanguage);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(activeLanguage);
  }, [activeLanguage]);

  const toggleLanguageModalIsOpen = () => {
    setIsLanguageModalOpen((prevState) => !prevState);
  };

  const handleNavigate = () => {
    navigate(homeHref);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <StyledAppBar>
        <Logo onClick={handleNavigate} sx={{ gridArea: 'logo' }}>
          <LogoIcon />
        </Logo>

        <ButtonGroup sx={{ gridArea: 'buttonGroup' }}>
          <Link
            to={createHref(ROUTES.nachrichten)}
            style={{ width: '100%', textDecoration: 'none' }}
          >
            <ButtonCSS variant='outlined' sx={{ gridArea: 'news', textDecoration: 'none' }}>
              {t('header.news')}
            </ButtonCSS>
          </Link>

          <ButtonCSS variant='outlined' onClick={onOpenDrawer} sx={{ gridArea: 'contactUs' }}>
            {t('header.contactUs')}
          </ButtonCSS>
        </ButtonGroup>
        <LanguageButtonStyling
          onClick={toggleLanguageModalIsOpen}
          sx={{ gridArea: 'languageButton' }}
        >
          <LanguageIconStyles />
          {activeLanguage && activeLanguage === 'de' ? (
            <DeLanguageLetterIcon />
          ) : (
            <EnLanguageLetterIcon />
          )}
          <Box sx={{ position: 'relative' }}>
            {isLanguageModalOpen && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '16px',
                  right: '0',
                  width: '179px',
                  backgroundColor: 'white',
                  borderRadius: '6px',
                }}
              >
                <Box
                  sx={{
                    padding: '12px 10px 12px 20px',
                    color: 'black',
                    fontSize: '14px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleChangeLanguage('de');
                    toggleLanguageModalIsOpen();
                  }}
                >
                  <Box style={{ color: palette.blue600 }}>Deutsch</Box>
                  {activeLanguage === 'de' && <CheckIcon />}
                </Box>
                <Box
                  sx={{
                    padding: '12px 10px 12px 20px',
                    color: 'black',
                    fontSize: '14px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleChangeLanguage('en');
                    toggleLanguageModalIsOpen();
                  }}
                >
                  <Box style={{ color: palette.blue600 }}>English</Box>
                  {activeLanguage === 'en' && <CheckIcon />}
                </Box>
              </Box>
            )}
          </Box>
        </LanguageButtonStyling>
      </StyledAppBar>
    </Box>
  );
};
