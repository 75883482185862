import { SxProps, Typography } from '@mui/material';
import { SyntheticEvent } from 'react';
import { palette } from '../../../theme/foundations';
import { ButtonTabs, Wrapper } from './styled';
import { TABS_OPTIONS } from '../../../constants';
import { useTranslation } from 'react-i18next';

type OptionType = {
  id: TABS_OPTIONS;
  label: string;
};

interface ITabsCustomProps {
  options: OptionType[];
  value: string;
  setValue: (value: TABS_OPTIONS) => void;
  mb?: string;
  sx?: SxProps;
}

export const TabsCustom = ({ options, value, setValue, mb, sx }: ITabsCustomProps) => {
  const [t] = useTranslation('global');
  const handleChange = (event: SyntheticEvent, newValue: TABS_OPTIONS) => {
    setValue(newValue);
  };

  return (
    <Wrapper
      sx={{
        marginBottom: mb,
        ...sx,
      }}
    >
      {options.map(({ id, label }) => {
        return (
          <ButtonTabs
            key={id}
            isactive={value === id ? 'true' : 'false'}
            onClick={(e) => handleChange(e, id)}
          >
            <Typography
              variant={'body2'}
              color={palette.white}
              fontWeight={value === id ? '600' : 'initial'}
            >
              {t(`forms.${label}`)}
            </Typography>
          </ButtonTabs>
        );
      })}
    </Wrapper>
  );
};
