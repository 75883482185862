import Box from '@mui/material/Box';
import { DescriptionStyles, ImageBackground, ImageWrapper } from './styles';
import { useTranslation } from 'react-i18next';

type Image = {
  url: string;
  description?: string;
  backgroundPosition?: Record<string, string>;
};

export const NewsImages = ({
  images,
  newsId,
  onOpen,
}: {
  images?: Image[];
  newsId?: string;
  onOpen: (index: number) => void;
}) => {
  let backgroundPositionStyle = {};
  const { t } = useTranslation();

  if (images && (newsId === '8' || newsId === '5' || newsId === '6')) {
    return (
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          paddingTop: '32%',
          cursor: 'pointer',
        }}
        onClick={() => onOpen(1)}
      >
        <ImageBackground
          image={newsId === '6' ? images[0].url : images[1].url}
          sx={{
            ...backgroundPositionStyle,
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        />
      </Box>
    );
  }

  if (images && newsId === '3') {
    return (
      <Box sx={{ width: { xs: '100%', md: '50%' } }} onClick={() => onOpen(0)}>
        <Box
          sx={{
            position: 'relative',
            paddingTop: newsId === '3' ? { xs: '80%', md: '120%' } : '100%',
            width: '100%',
            cursor: 'pointer',
          }}
        >
          <ImageBackground
            image={images[0].url}
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              backgroundPosition: { xs: 'right 85% top 80%', md: 'right 85% top 27%' },
            }}
          />
        </Box>
      </Box>
    );
  }

  if (images && newsId === '2') {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '20px', md: '28px' },
        }}
      >
        {images.map(({ url, description }, index) => (
          <Box sx={{ width: { md: '50%', xs: '100%' } }} onClick={() => onOpen(index)}>
            <Box
              sx={{
                position: 'relative',
                paddingTop: '56.5%',
                cursor: 'pointer',
              }}
            >
              <ImageBackground
                image={url}
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  backgroundPosition: { xs: 'right 85% top 55%', md: 'right 85% top 40%' },
                }}
              />
            </Box>
            {description && <DescriptionStyles>{t(description)}</DescriptionStyles>}
          </Box>
        ))}
      </Box>
    );
  }

  if (images) {
    // Отрисовка изображений, если они или оно не попадает под исключения, которые представлены выше
    return (
      <ImageWrapper>
        {images.map(({ url, description, backgroundPosition }, index) => (
          <Box sx={{ width: '100%' }} onClick={() => onOpen(index)}>
            <Box
              sx={{
                position: 'relative',
                paddingTop: newsId === '4' ? '70%' : '80%',
                cursor: 'pointer',
              }}
            >
              <ImageBackground
                image={url}
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  backgroundPosition: {
                    xs: backgroundPosition ? backgroundPosition.xs : 'right 85% top 55%',
                    md: backgroundPosition ? backgroundPosition.md : 'right 85% top 40%',
                  },
                }}
              />
            </Box>
            {description && <DescriptionStyles>{t(description)}</DescriptionStyles>}
          </Box>
        ))}
      </ImageWrapper>
    );
  }
  return null;
};
