import { Box, Typography } from '@mui/material';
import { ImageBackground, textStyles, Wrapper } from './styles';
import { useTranslatedNewsData } from '../../assets/data/news';
import { Link, Outlet, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useCustomHref } from '../../hooks/useCustomHref';
import { Trans, useTranslation } from 'react-i18next';

export const NachrichtPage = () => {
  const { createHref } = useCustomHref();
  const newsData = useTranslatedNewsData();
  const topNews = newsData.slice(0, 3);
  const bottomNews = newsData.slice(3);
  const { id } = useParams();
  const [t] = useTranslation('global');

  return (
    <Wrapper
      sx={{
        mt: '36px',
        mb: '210px',
        maxWidth: '1120px',
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: { xs: '20px', sm: '59x', md: '20px' },
      }}
    >
      {!id ? (
        <Box>
          <Typography sx={{ fontSize: '34px', pb: '24px' }}> {t('header.news')}</Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'minmax(0, 1fr)',
                sm: 'repeat(3, minmax(0, 1fr))',
              },
              gap: '28px',
            }}
          >
            {topNews.map((news) => (
              <Box>
                <Link
                  to={createHref(`${ROUTES.nachrichten}/${news.id}`)}
                  style={{ textDecoration: 'inherit', color: 'inherit' }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      position: 'relative',
                      paddingTop: '110%',
                    }}
                  >
                    {news.images && (
                      <ImageBackground
                        firstImage={
                          news.id && news?.id === 7 ? news.images[1]?.url : news.images[0]?.url
                        }
                        sx={{
                          backgroundPosition:
                            news.id === 2 || news.id === 1
                              ? 'left 63% bottom 50%'
                              : 'left 45% bottom 85%',
                          position: 'absolute',
                          left: 0,
                          top: 0,
                        }}
                      />
                    )}
                  </Box>

                  <Typography sx={{ pt: '18px', pb: '4px', fontSize: '15px' }}>
                    {news.date && t(news.date)}
                  </Typography>
                  <Typography
                    sx={{
                      ...textStyles.title,
                      ...textStyles.cropped,
                      fontSize: '24px',
                      lineHeight: '32px',
                      WebkitLineClamp: 2,
                    }}
                  >
                    {news?.title && t(news?.title)}
                  </Typography>
                  <Typography
                    sx={{
                      ...textStyles.content,
                      ...textStyles.cropped,
                      WebkitLineClamp: 3,
                      fontSize: '12px',
                      lineHeight: '20px',
                      pt: '8px',
                    }}
                  >
                    <Trans
                      i18nKey={(t(`${news.paragraphs}`, { returnObjects: true }) as string[])[0]}
                      components={{
                        typography: (
                          <Typography
                            sx={{
                              WebkitLineClamp: 3,
                              fontSize: '12px',
                              lineHeight: '20px',
                            }}
                          />
                        ),
                        typographyHide: (
                          <Typography
                            sx={{
                              visibility: 'hidden',
                            }}
                          />
                        ),
                      }}
                    />
                    {/*{news.paragraphs &&
                      (t(`${news.paragraphs}`, { returnObjects: true }) as string[])[0]}*/}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              pt: { xs: '24px', md: '40px' },
              justifyContent: 'space-between',
              gridTemplateColumns: {
                xs: 'repeat(2, minmax(0, 1fr))',
                md: 'repeat(4, minmax(0, 1fr))',
              },
              gap: { xs: '20px', md: '28px' },
            }}
          >
            {bottomNews.map((news) => (
              <Box>
                <Link
                  to={createHref(`${ROUTES.nachrichten}/${news.id}`)}
                  style={{ textDecoration: 'inherit', color: 'inherit' }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      position: 'relative',
                      paddingTop: { xs: '57%', md: '72%' },
                    }}
                  >
                    {news.images && (
                      <ImageBackground
                        firstImage={
                          (news.id && news?.id === 7) || news?.id === 1
                            ? news.images[1]?.url
                            : news.images[0]?.url
                        }
                        sx={{
                          backgroundPosition: 'left 45% bottom 50%',
                          position: 'absolute',
                          left: 0,
                          top: 0,
                        }}
                      />
                    )}
                  </Box>

                  <Typography sx={{ pt: '18px', pb: '4px', fontSize: '15px' }}>
                    {news.date && t(news.date)}
                  </Typography>
                  <Typography
                    sx={{
                      ...textStyles.title,
                      ...textStyles.cropped,
                      fontSize: '24px',
                      lineHeight: '32px',
                      WebkitLineClamp: 2,
                    }}
                  >
                    {news.title && t(news.title)}
                  </Typography>
                  <Typography
                    sx={{
                      ...textStyles.content,
                      ...textStyles.cropped,
                      WebkitLineClamp: 3,
                      fontSize: '12px',
                      lineHeight: '20px',
                      pt: '8px',
                    }}
                  >
                    <Trans
                      i18nKey={(t(`${news.paragraphs}`, { returnObjects: true }) as string[])[0]}
                      components={{
                        typography: (
                          <Typography
                            sx={{
                              WebkitLineClamp: 3,
                              fontSize: '12px',
                              lineHeight: '20px',
                            }}
                          />
                        ),
                        typographyHide: (
                          <Typography
                            sx={{
                              visibility: 'hidden',
                            }}
                          />
                        ),
                      }}
                    />
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Outlet />
      )}
    </Wrapper>
  );
};
