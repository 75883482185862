import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotFoundWrapper } from './styles';

type NotFoundPageProps = {
  errorText?: string;
};

const NotFoundPage = ({ errorText = 'general' }: NotFoundPageProps) => {
  const { t } = useTranslation('global');
  const aboutError = t(`notFound.${errorText}`);

  return (
    <NotFoundWrapper>
      <Typography variant='h3' marginBottom='20px'>
        {t(`notFound.title`)}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: '16px', md: '24px' },
        }}
      >
        {aboutError}
      </Typography>
    </NotFoundWrapper>
  );
};

export default NotFoundPage;

/*export const getStaticProps = async ({ locale }) => {
  try {
    return {
      props: {
        ...(await serverSideTranslations(locale ?? 'sr')),
      },
    };
  } catch {
    return {
      notFound: true,
    };
  }
};*/
