import { IconComponentPropsType } from '../../types';

export const EnLanguageLetterIcon = ({
  width = '100%',
  height = '100%',
  style,
}: // color = palette.white100,
IconComponentPropsType) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={style}
  >
    <path
      d='M0 12V0H8.02967V2.0918H2.51946V4.95117H7.61655V7.04297H2.51946V9.9082H8.05295V12H0Z'
      fill='white'
    />
    <path
      d='M20 0V12H17.8238L12.6395 4.44727H12.5522V12H10.0327V0H12.2438L17.3874 7.54688H17.4922V0H20Z'
      fill='white'
    />
  </svg>
);
