import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: '39px',
  marginBottom: '130px',
  userSelect: 'none',
  width: '100%',
  height: '100%',
}));
