import * as yup from 'yup';

export const createProjektentwicklerFormSchema = (t: (key: string) => string) => {
  return yup.object().shape({
    name: yup.string().required(t('messages.requireMessage')),
    firma: yup.string().required(t('messages.requireMessage')),
    phoneNumber: yup
      .string()
      .matches(/^[\d ()+-]+$/, t('messages.incorrectFormat'))
      .required(t('messages.requireMessage')),
    email: yup.string().email(t('messages.incorrectEmail')).required(t('messages.requireMessage')),
    website: yup.string(),
    message: yup.string(),
  });
};
