import { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';

import { Wrapper } from './styles';

export const ImmobilienZeitung = () => {
  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument, pdfPage } = usePdf({
    file: 'forDisplayPdf/iz.pdf',
    scale: 1.5,
    page,
    canvasRef,
  });

  const handleContextMenu = (e: React.SyntheticEvent) => e.preventDefault();

  return (
    <Wrapper onContextMenu={handleContextMenu}>
      {!pdfDocument && <span>Loading...</span>}
      <canvas ref={canvasRef} />
    </Wrapper>
  );
};
