import { IconComponentPropsType } from '../../types';
import innovationsradar from '../../assets/images/innovationsradar.jpg';

export const InnovationsradarIcon = ({
  width = '82px',
  height = '32px',
  style,
}: IconComponentPropsType) => {
  return (
    <img
      width={width}
      height={height}
      style={style}
      src={innovationsradar}
      alt='innovationsradar'
    />
  );
};
