import { FormCSS, LoadingButtonCSS, ProjektentwicklerFormTitleCSS, Wrapper } from '../styled';
import { FormProvider, useForm } from 'react-hook-form';
import { TextFieldRHF } from '../../UI/TextFieldRHF/TextFieldRHF';
import { yupResolver } from '@hookform/resolvers/yup';
import { createProjektentwicklerFormSchema } from '../../../validation';
import { useAlertContext } from '../../../context';
import { useRegisterDeveloperMutation } from '../../../graphql/generated';
import { ChevronRightIcon } from '../../icons';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

type FormValues = {
  name: string;
  firma: string;
  phoneNumber: string;
  email: string;
  website: string | undefined;
  message: string | undefined;
};

const INIT_VALUE = {
  name: '',
  firma: '',
  phoneNumber: '',
  email: '',
  website: '',
  message: '',
};

export const ProjektentwicklerForm = () => {
  const [t] = useTranslation('global');
  const { onOpenAlert } = useAlertContext();
  const projektentwicklerFormSchema = useMemo(() => createProjektentwicklerFormSchema(t), [t]);

  const methods = useForm<FormValues>({
    defaultValues: INIT_VALUE,
    resolver: yupResolver(projektentwicklerFormSchema),
  });
  const { handleSubmit, reset } = methods;

  const [registerDeveloper, { loading }] = useRegisterDeveloperMutation({
    onCompleted: () => {
      reset(INIT_VALUE);
      onOpenAlert({
        title: t('forms.successMessage'),
      });
    },
    onError: (error) => {
      onOpenAlert({ title: t('forms.errorMessage'), subTitle: error.message, error: true });
    },
  });

  const onSubmitRHF = (value: FormValues) => {
    registerDeveloper({
      variables: {
        input: {
          lastName: value.name,
          companyName: value.firma,
          phoneNumber: value.phoneNumber,
          email: value.email,
          website: value.website || '',
          message: value.message || '',
        },
      },
    });
  };

  return (
    <Wrapper>
      <ProjektentwicklerFormTitleCSS mb={'28px'}>
        {t('forms.projectRequest')}
      </ProjektentwicklerFormTitleCSS>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitRHF)}>
          <FormCSS>
            <TextFieldRHF
              name={'name'}
              placeholder={t('forms.namePlaceholder')}
              label={t('forms.nameLabel')}
            />
            <TextFieldRHF
              name={'firma'}
              placeholder={t('forms.companyPlaceholder')}
              label={t('forms.companyLabel')}
            />
            <TextFieldRHF
              type={'tel'}
              name={'phoneNumber'}
              placeholder={t('forms.telPlaceholder')}
              label={t('forms.telLabel')}
            />
            <TextFieldRHF
              name={'email'}
              placeholder={t('forms.emailPlaceholder')}
              label={t('forms.emailLabel')}
            />
            <TextFieldRHF
              name={'website'}
              placeholder={t('forms.webSitePlaceholder')}
              label={t('forms.webSiteLabel')}
            />
            <TextFieldRHF
              name={'message'}
              placeholder={t('forms.messagePlaceholder')}
              label={t('forms.messageLabel')}
              multiline
              minRows={6}
              maxRows={6}
            />
          </FormCSS>
          <LoadingButtonCSS
            type={'submit'}
            variant={'submit'}
            endIcon={<ChevronRightIcon />}
            loading={loading}
            loadingPosition='end'
          >
            {t('forms.submit')}
          </LoadingButtonCSS>
        </form>
      </FormProvider>
    </Wrapper>
  );
};
