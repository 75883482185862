import { useSearchParams } from 'react-router-dom';

import { AVAILABLE_LANGS } from '../constants';
import { Language } from '../types';

const LANGUAGE_URL_KEY = 'lang';

export const useUrlLang = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setUrlLanguage = (lang: Language | null): void => {
    lang && setSearchParams({ [LANGUAGE_URL_KEY]: lang }, { replace: true });
  };

  const urlLanguageRaw = searchParams.get(LANGUAGE_URL_KEY) || '';

  const urlLanguage: Language = AVAILABLE_LANGS.includes(urlLanguageRaw)
    ? (urlLanguageRaw as Language)
    : 'de';

  return {
    urlLanguageRaw,
    urlLanguage,
    setUrlLanguage,
  };
};
