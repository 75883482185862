import { Layout } from 'components';
import { createBrowserRouter } from 'react-router-dom';

import { DatenschutzPage, Home, ImmobilienZeitung, ImpressumPage, NachrichtPage } from '../pages';
import { ROUTES } from '../constants';
import { NachrichtDetailedPage } from '../pages/NachrichtPage/NachrichtDetailedPage';
import NotFoundPage from '../pages/NotFoundPage';

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: ROUTES.home,
        element: <Home />,
      },
      {
        path: ROUTES.datenschutz,
        element: <DatenschutzPage />,
      },
      {
        path: ROUTES.nachrichten,
        element: <NachrichtPage />,
        children: [
          {
            path: ':id',
            element: <NachrichtDetailedPage />,
          },
        ],
      },
      {
        path: ROUTES.impressum,
        element: <ImpressumPage />,
      },
      {
        path: ROUTES.immobilienzeitung,
        element: <ImmobilienZeitung />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);

export default router;
