import { langLocalStorageHandler } from '../utils/langLocalStorageHandler';
import { useUrlLang } from '../hooks/useUrlLang';

export const useCustomHref = () => {
  const { urlLanguageRaw } = useUrlLang();

  const createHref = (path: string, localize = true) => {
    const langPart = urlLanguageRaw ? `?lang=${langLocalStorageHandler.getLanguage()}` : '';

    if (!localize) {
      return path;
    }

    return `${path}${langPart}`;
  };

  return {
    createHref,
  };
};
