import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

interface NewsParagraphsProps {
  paragraphs: string[] | undefined;
  id: string | undefined;
}

export const NewsParagraphs: React.FC<NewsParagraphsProps> = ({ paragraphs, id }) => {
  const [t] = useTranslation('global');

  if (id && id === '7') {
    const i18nKey = paragraphs || 'default.key';

    return (
      <Trans
        i18nKey={t(i18nKey)}
        components={{
          typography: (
            <Typography
              sx={{ fontSize: '15px', lineHeight: '24px', pb: { xs: '6px', md: '12px' } }}
            />
          ),

          refdLink: (
            <Link
              style={{ color: 'inherit', textDecoration: 'inherit' }}
              to='https://www.targacommunications.de/'
              target='_blank'
            />
          ),
        }}
      />
    );
  }
  if (id && id === '3') {
    const i18nKey = paragraphs || 'default.key';

    return (
      <Trans
        i18nKey={t(i18nKey)}
        components={{
          typography: (
            <Typography
              sx={{ fontSize: '15px', lineHeight: '24px', pb: { xs: '6px', md: '12px' } }}
            />
          ),
          typographyLow: <Typography sx={{ fontSize: '15px', lineHeight: '24px' }} />,
          typographyHide: <Typography sx={{ fontSize: '15px', lineHeight: '24px' }} />,
        }}
      />
    );
  }

  return (
    <>
      {paragraphs?.map((item) => (
        <Typography
          key={item}
          sx={{ fontSize: '15px', lineHeight: '24px', pb: { xs: '6px', md: '12px' } }}
        >
          {item}
        </Typography>
      ))}
    </>
  );
};
